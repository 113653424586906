<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Below is a TLC plate of a reaction of DHZ and NaBH4. Lane 1 is the starting material (SM).
        Lane 2 is the cospot (Co). Lane 3 is the reaction product (Pdt). Based on this TLC plate,
        has the reaction come to completion and why?
      </p>

      <p class="mb-4 pl-6">
        <v-img style="max-width: 200px" src="/img/assignments/UCIrvine/tlcLanes.png" />
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 ml-6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI51PLFS1Q4',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {text: 'Yes, because lane (SM) 1 only has one spot.', value: 'a'},
        {text: 'No, because lane 2 (Co) has 2 spots.', value: 'b'},
        {text: 'Yes, because lane 3 (Pdt) has two spots.', value: 'c'},
        {text: 'No, because lane 3 (Pdt) has 2 spots.', value: 'd'},
      ],
    };
  },
};
</script>
<style scoped></style>
